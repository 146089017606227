/** @jsx jsx */
// @ts-check
import BaseProducersPage from '@bottlebooks/gatsby-theme-event/src/components/ProducersPage/ProducersPage';
import { jsx } from 'theme-ui';

export * from '@bottlebooks/gatsby-theme-event/src/components/ProducersPage/ProducersPage';

export default function ProducersPage(props) {
  return (
    <BaseProducersPage
      {...props}
      withEventBanner
      withEventBannerOverlay={false}
    />
  );
}
